export const SECTIONS = {
  ABOUT: { ACTION: 'about', LABEL: 'About Me' },
  SKILLS: { ACTION: 'skills', LABEL: 'Skills' },
  EXP: { ACTION: 'experience', LABEL: 'Experience' },
  PROJECTS: { ACTION: 'projects', LABEL: 'Projects' },
  EDU: { ACTION: 'education', LABEL: 'Education' },
  CONTACT: { ACTION: 'contact', LABEL: 'Contact' },
};

export const skillsData = [
  {
    title: 'UI Side',
    description:
      'HTML5, CSS3/SCSS, ES5, ES6, DOM, BOM, Typescript, Web Accessibility,  React/Redux, React-Hooks, React-use, Axios, Styled-component, Rebass/grid, Ant-design, Lodash, Jest, Enzyme, React Testing Library, Cypress, Cucumber',
  },
  {
    title: 'API Side',
    description:
      'CommonJS, Node.js, Express, Restful API, Swagger, Mongoose, SQL',
  },
  {
    title: 'Tools & Platform',
    description: 'Git, Jira, Bitbucket, Github, Postman, Webpack',
  },
];

export const expData = [
  {
    label: 'Jan 2022 - present',
    position: 'React Developer',
    company: 'Westpac - Sydney',
  },
  {
    label: 'Jul 2018 - Dec 2021',
    position: 'Front End Developer',
    company: 'Guangzhou Yuepai Information Technology Co.LTD',
  },
  {
    label: 'Before',
    position: 'A University Lecturer',
    company: '',
  },
];

export const projectsData = [
  {
    title: 'WDP mesh connect',
    description:
      'The project was providing a platform for company internal use to administer all development resources',
  },
  {
    title: 'A school education management system',
    description:
      'The project was providing a management platform for the high school managing information of teachers, students and curriculum',
  },
  {
    title: 'An e-commerce website',
    description: 'The project was for building a blind-boxes e-commerce site',
  },
];

export const eduData = [
  {
    degree: 'Degree of Bachelor',
    school: 'Shaanxi University of Science & Technology, Xi’an,	China',
  },
  {
    degree: 'Degree of Master',
    school: 'Shaanxi University of Science & Technology, Xi’an,	China',
  },
];
