import styled from 'styled-components';
import { Modal } from 'antd';

export const StyledModal = styled(Modal)`
  width: 90% !important;
  max-width: 1000px !important;
  padding: 0;

  .ant-modal-content .ant-modal-header,
  .ant-modal-content .ant-modal-body {
    padding: 30px 50px;
  }
  .ant-modal-content .ant-modal-header {
    background-color: ${({ theme }) => theme.colors.quaternary};
  }
  .ant-modal-content .ant-modal-header .ant-modal-title {
    font-size: ${({ theme }) => theme.fontSizes[5]}px;
    color: ${({ theme }) => theme.colors.white};
  }
  .ant-modal-content .ant-modal-body {
    line-height: ${({ theme }) => theme.lineHeights.body};
    font-size: ${({ theme }) => theme.fontSizes[4]}px;
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
  }
  .ant-list-item-meta-content .ant-list-item-meta-title,
  .ant-list-item-meta-content .ant-list-item-meta-description {
    font-size: ${({ theme }) => theme.fontSizes[3]}px;
    color: ${({ theme }) => theme.colors.white};
    font-style: italic;
    line-height: ${({ theme }) => theme.lineHeights.list};
  }

  .ant-timeline {
    padding-top: 50px;
  }
  .ant-timeline-item {
    font-size: ${({ theme }) => theme.fontSizes[3]}px;
    color: ${({ theme }) => theme.colors.white};
  }
  .ant-timeline-item .ant-timeline-item-label {
    width: calc(30% - 12px);
  }
  .ant-timeline-item .ant-timeline-item-tail,
  .ant-timeline-item .ant-timeline-item-head {
    left: 30%;
  }
  .ant-timeline-item .ant-timeline-item-content {
    left: calc(30% - 4px) !important;
    width: calc(70% - 14px) !important;
  }
`;
