import 'reset-css';
import styled from 'styled-components';
import { ThemeProvider } from 'styled-components';
import 'antd/dist/antd.min.css';
import Home from './components/Home';

const theme = {
  breakpoints: ['32em', '48em', '64em'],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
  colors: {
    white: '#fff',
    primary: '#795e83', //'#182747',
    secondary: '#5b0158', //'#562B08',
    tertiary: '#274f7a', //'#647E68',
    quaternary: '#f5e278', //'#D8D8D8',
    fifth: '#06f7fa', //'#07c',
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  fonts: {
    body: 'system-ui, sans-serif',
    heading: 'inherit',
    monospace: 'Menlo, monospace',
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    list: 2,
    body: 1.5,
    heading: 1.25,
  },
  shadows: {
    small: '0 0 4px rgba(0, 0, 0, .125)',
    large: '0 0 24px rgba(0, 0, 0, .125)',
  },
  variants: {},
  text: {},
  buttons: {
    primary: {
      color: 'white',
      bg: 'navy',
    },
  },
};

const StyledContainer = styled.div`
  max-width: 1200px;
  margin: auto;
  font-family: Arial, Helvetica, sans-serif;
`;

function App() {
  return (
    <ThemeProvider theme={theme}>
      <StyledContainer className='App'>
        <Home />
      </StyledContainer>
    </ThemeProvider>
  );
}

export default App;
