import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useMouse } from 'react-use';
import arrow from '../../assets/level-tone-arrow-th.png';

const Wrapper = styled.div`
  ${(props) =>
    `width: ${props.width * 2 + 10}px; height: ${props.width * 2 + 10}px;}`}
  border-radius: 50%;
  background: radial-gradient(
    ${(props) => props.theme.colors.fifth},
    transparent
  );
  display: flex;
  align-items: center;
  & > div {
    width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;
    margin-left: ${(props) => props.width + 5}px;
    background-image: url(${arrow});
    background-size: 100% 100%;
    transform-origin: left center;
    transform: rotate(${(props) => props.degree}deg);
  }
`;

const useDegree = (ref, width) => {
  const [degree, setDegree] = useState(0);
  const { docX, docY, posX, posY } = useMouse(ref);
  useEffect(() => {
    const x = parseInt(docX - posX - width - 5);
    const y = parseInt(docY - posY - width - 5);
    const degree = (Math.atan2(y, x) * 180) / Math.PI;
    setDegree(degree);
  }, [docX, docY, posX, posY, width]);
  return { degree };
};

const MousePositionCompass = ({ width = 50, height = 20 }) => {
  const ref = useRef(null);
  const { degree } = useDegree(ref, width);
  return (
    <Wrapper ref={ref} width={width} height={height} degree={degree}>
      <div>
        {/* <img width='100%' height='100%' src={arrow} alt='arrow' /> */}
      </div>
    </Wrapper>
  );
};

export default MousePositionCompass;
