import React, { useEffect, useState } from 'react';
import { Flex } from 'rebass';
import { SECTIONS } from '../../constants';
import Hero from '../Hero';
import MousePositionCompass from '../MouseDirectionArrow';
import SectionModal from '../SectionModal';
import {
  BottomCompassWrapper,
  Container,
  FifthBox,
  FirstBox,
  FourthBox,
  SecondBox,
  SixthBox,
  ThirdBox,
  TopCompassWrapper,
} from './styles';

const Home = () => {
  const [action, setAction] = useState('');
  const [isShowNav, setShowNav] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowNav(true);
    }, 7500);
  }, []);

  const handleClick = (clickAction) => {
    setAction(clickAction);
  };

  const handleCancel = () => {
    setAction('');
  };
  return (
    <>
      <Hero />
      <Container id='nav' isShowNav={isShowNav}>
        <Flex>
          <FirstBox>
            <span onClick={() => handleClick(SECTIONS.ABOUT.ACTION)}>
              {SECTIONS.ABOUT.LABEL}
            </span>
          </FirstBox>
          <SecondBox>
            <span onClick={() => handleClick(SECTIONS.SKILLS.ACTION)}>
              {SECTIONS.SKILLS.LABEL}
            </span>
          </SecondBox>
        </Flex>
        <Flex>
          <ThirdBox>
            <span onClick={() => handleClick(SECTIONS.EXP.ACTION)}>
              {SECTIONS.EXP.LABEL}
            </span>
          </ThirdBox>
          <FourthBox>
            <span onClick={() => handleClick(SECTIONS.PROJECTS.ACTION)}>
              {SECTIONS.PROJECTS.LABEL}
            </span>
          </FourthBox>
        </Flex>
        <Flex>
          <FifthBox>
            <span onClick={() => handleClick(SECTIONS.EDU.ACTION)}>
              {SECTIONS.EDU.LABEL}
            </span>
          </FifthBox>
          <SixthBox>
            <span onClick={() => handleClick(SECTIONS.CONTACT.ACTION)}>
              {SECTIONS.CONTACT.LABEL}
            </span>
          </SixthBox>
        </Flex>
        <TopCompassWrapper>
          <MousePositionCompass width={30} height={15} />
        </TopCompassWrapper>
        <BottomCompassWrapper>
          <MousePositionCompass width={30} height={15} />
        </BottomCompassWrapper>
        <SectionModal action={action} handleCancel={handleCancel} />
      </Container>
    </>
  );
};

export default Home;
