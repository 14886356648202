import React, { useState, useEffect } from 'react';
// import { Image } from 'antd';
import ReactTypingEffect from 'react-typing-effect';
import { StyledDiv, Typing } from './styles';
import hero from '../../assets/keyboard.jpg';

const Hero = () => {
  const [isHidden, SetHide] = useState(false);
  const [isHeroFade, setHeroFade] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      SetHide(true);
    }, 5500);
    setTimeout(() => {
      setHeroFade(true);
    }, 7500);
  }, []);
  return (
    <StyledDiv isHeroFade={isHeroFade}>
      <img width='100%' src={hero} alt='hero' />
      <Typing isHidden={isHidden}>
        <ReactTypingEffect
          staticText='Hi, I am a '
          text={['Front End', 'React Developer !']}
          speed={100}
          eraseSpeed={40}
          eraseDelay={1000}
          typingDelay={400}
        />
        <p>
          <span>INTERESTED ?</span>
        </p>
      </Typing>
    </StyledDiv>
  );
};

export default Hero;
