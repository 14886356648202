import React, { useMemo } from 'react';
import { Avatar, List, Timeline } from 'antd';
import { LinkedinOutlined, MailOutlined } from '@ant-design/icons';
import {
  eduData,
  expData,
  projectsData,
  SECTIONS,
  skillsData,
} from '../../constants';
import { StyledModal } from './styles';
import png from '../../assets/key.png';
import pieChart from '../../assets/pie_chart.png';
import note from '../../assets/note.png';

const SectionModal = ({ action, handleCancel }) => {
  const modalTitle = useMemo(() => {
    switch (action) {
      case SECTIONS.ABOUT.ACTION:
        return SECTIONS.ABOUT.LABEL;
      case SECTIONS.SKILLS.ACTION:
        return SECTIONS.SKILLS.LABEL;
      case SECTIONS.EXP.ACTION:
        return SECTIONS.EXP.LABEL;
      case SECTIONS.PROJECTS.ACTION:
        return `Main ${SECTIONS.PROJECTS.LABEL}`;
      case SECTIONS.EDU.ACTION:
        return SECTIONS.EDU.LABEL;
      case SECTIONS.CONTACT.ACTION:
        return `${SECTIONS.CONTACT.LABEL} Me`;
      default:
        return '';
    }
  }, [action]);

  return (
    <StyledModal
      title={modalTitle}
      open={!!action}
      onCancel={handleCancel}
      footer={null}
    >
      {action === SECTIONS.ABOUT.ACTION && (
        <>
          <p>
            Hi, I am Justin, a passionate and self-motivated front-end developer
            based in Sydney. I keep improving myself for writing more readable,
            more maintainable and more reusable codes. In my free time, you can
            find me hiking in the mountains, long boarding at the beach, or
            reading books at home.
          </p>
          <p>
            Now I am seeking a challenging position of Web Developer to put
            several years of experience into use. I would utilize my technical
            skills and strong teamwork spirit for the growth of the company as
            well as enhance my knowledge about new and emerging trends in the
            web developing field.
          </p>
        </>
      )}
      {action === SECTIONS.SKILLS.ACTION && (
        <List
          itemLayout='horizontal'
          dataSource={skillsData}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar src={png} />}
                title={item.title}
                description={item.description}
              />
            </List.Item>
          )}
        />
      )}
      {action === SECTIONS.EXP.ACTION && (
        <Timeline mode='left'>
          {expData.map((item) => (
            <Timeline.Item label={item.label}>
              {item.position}
              <br />
              {item.company}
            </Timeline.Item>
          ))}
        </Timeline>
      )}
      {action === SECTIONS.PROJECTS.ACTION && (
        <List
          itemLayout='horizontal'
          dataSource={projectsData}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar src={pieChart} />}
                title={item.title}
                description={item.description}
              />
            </List.Item>
          )}
        />
      )}
      {action === SECTIONS.EDU.ACTION && (
        <List
          itemLayout='horizontal'
          dataSource={eduData}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar src={note} />}
                title={item.degree}
                description={item.school}
              />
            </List.Item>
          )}
        />
      )}
      {action === SECTIONS.CONTACT.ACTION && (
        <>
          <a href='https://www.linkedin.com/in/junxi-zheng-3083b9220/'>
            <LinkedinOutlined style={{ color: 'blue' }} />
            &nbsp;&nbsp; linkedin.com/in/junxi-zheng
          </a>
          <br />
          <br />
          <a href='mailto:junxizhengim@gmail.com'>
            <MailOutlined style={{ color: 'green' }} />
            &nbsp;&nbsp; Mail to me
          </a>
          <br />
          <br />
        </>
      )}
    </StyledModal>
  );
};

export default SectionModal;
