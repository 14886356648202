import styled from 'styled-components';

export const StyledDiv = styled.div`
  position: relative;
  height: ${({ isHeroFade }) => (isHeroFade ? `0px` : `100vh;`)};
  transition: height 2s;
  overflow: hidden;
`;

export const Typing = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > span {
    ${({ isHidden }) => isHidden && `display: none;`}
  }
  & span {
    color: ${({ theme }) => theme.colors.white};
    font-size: ${({ theme }) => theme.fontSizes[5]}px;
    font-weight: ${({ theme }) => theme.fontWeights.heading}px;
  }
  & > p {
    ${({ isHidden }) => (isHidden ? `opacity: 1;` : `opacity: 0;`)}
    transition: opacity 1.5s;
    color: ${({ theme }) => theme.colors.white};
  }
`;
