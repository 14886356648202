import styled from 'styled-components';
import { Box } from 'rebass';

export const Container = styled.div`
  position: relative;
  display: ${({ isShowNav }) => (isShowNav ? 'block' : 'none')};
`;

const StyledBox = styled(Box)`
  width: 50%;
  height: 33.33vh;
  line-height: 33.33vh;
  text-align: center;
  & > span {
    padding: ${({ theme }) => theme.fontSizes[5]}px;
    font-size: ${({ theme }) => theme.fontSizes[5]}px;
    transition: font-size, 0.5s;
    cursor: pointer;
    user-select: none;
  }

  & > span:hover {
    font-size: ${({ theme }) => theme.fontSizes[6]}px;
  }
`;

const StyledDiv = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-35px, -35px);
`;

export const TopCompassWrapper = styled(StyledDiv)`
  top: 33.33vh;
`;
export const BottomCompassWrapper = styled(StyledDiv)`
  top: 66.67vh;
`;

export const FirstBox = styled(StyledBox)`
  background-color: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.white};
`;

export const SecondBox = styled(StyledBox)`
  background-color: ${({ theme }) => theme.colors.quaternary};
  color: ${({ theme }) => theme.colors.white};
`;

export const ThirdBox = styled(StyledBox)`
  background-color: ${({ theme }) => theme.colors.quaternary};
  color: ${({ theme }) => theme.colors.white};
`;

export const FourthBox = styled(StyledBox)`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
`;

export const FifthBox = styled(StyledBox)`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
`;

export const SixthBox = styled(StyledBox)`
  background-color: ${({ theme }) => theme.colors.tertiary};
  color: ${({ theme }) => theme.colors.white};
`;
